import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '../public/css/app_1.min.scss'
import '../public/css/app_2.min.scss'
import '../public/css/styles.scss'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
//   authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.VUE_APP_FIREBASE_APP_ID,
//   measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
// }

// import Flutterwave from  'flutterwave-vue-v3'
// Vue.use(Flutterwave, { publicKey: 'FLWPUBK-84208607208a894e0cf3067b624824de-X' })



const firebaseConfig = {
	apiKey: "AIzaSyCgC1vTLRsccW-Pmdp-RZkrEkXeVtsKCbQ",
	authDomain: "smart-kast-votes.firebaseapp.com",
	projectId: "smart-kast-votes",
	storageBucket: "smart-kast-votes.appspot.com",
	messagingSenderId: "201278105224",
	appId: "1:201278105224:web:0ded3ab8729c9e759e213d"
};

const recaptchaKey = '6Le-pDYoAAAAACSjzcbMtdHPokX0aPMtXAH5thHd';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider(recaptchaKey),
	isTokenAutoRefreshEnabled: true
});

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll, {
	duration: 400,
	updateHistory: false,
})

import Placeholder from './components/Placeholder';
Vue.component('Placeholder', Placeholder);

import NotFound from './components/NotFound';
Vue.component('NotFound', NotFound);

import Spinner from './components/Spinner';
Vue.component('Spinner', Spinner);

import VueProgressiveImage from 'vue-progressive-image'
Vue.use(VueProgressiveImage);

import VueContentPlaceholders from 'vue-content-placeholders'
Vue.use(VueContentPlaceholders);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import VLazyImage from "v-lazy-image/v2";
Vue.component("VLazyImage", VLazyImage);

import VueIzitoast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
Vue.use(VueIzitoast);

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
