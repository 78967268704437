<template>
    <div class="home"  @scroll="onScroll">
        <AppHeader />

        <router-view />

		<AppFooter />

    </div>
</template>

<script>
export default {
    components: {
        AppHeader: () => import("@/components/Header.vue"),
		AppFooter: () => import("@/components/Footer.vue"),
    },
    methods: {
        onScroll(){
            // if (window.scrollTo() >= 200) {
            //     this.isFixed = true
            //     alert("hello Yesyesyes")
            // } else {
            //     this.isFixed = false
            //     alert("hello Nonono")
            // }
        }
    }
};
</script>
