import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('../views/About.vue')
	},
	{
		path: '/terms',
		name: 'Terms',
		component: () => import('../views/Terms.vue')
	},
	{
		path: '/privacy',
		name: 'Privacy',
		component: () => import('../views/Privacy.vue')
	},
	{
		path: '/news',
		name: 'News',
		component: () => import('../views/About.vue')
	},
	{
		path: '/gallery',
		name: 'Gallery',
		component: () => import('../views/About.vue')
	},
	{
		path: '/videos',
		name: 'Videos',
		component: () => import('../views/About.vue')
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import('../views/Contact.vue')
	},
	{
		path: '/events',
		redirect: '/'
	},
	{
		path: '/events/c/:eventId',
		component: () => import('../views/Contest.vue')
	},
	{
		path: '/events/c/:eventId/c/:categoryId',
		component: () => import('../views/Nominations.vue')
	},
	{
		path: '/events/c/:eventId/c/:categoryId/n/:nomineeId',
		component: () => import('../views/Checkout.vue')
	},
	{
		path: '/events/c/:eventId/n/:nomineeId',
		component: () => import('../views/Checkout.vue')
	},
	{
		path: '/events/f/:eventId',
		component: () => import('../views/Fundraiser.vue')
	},
	{
		path: '/events/r/:eventId',
		component: () => import('../views/Registration.vue')
	},
	{
		path: '/verify',
		component: () => import('../views/Verify.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
